$(document).ready(function() {
	// page : solution slick scripts
	// pick a topic
	if(!document.querySelector('.solutions-contents') && !document.querySelector('.pick-a-product-category')) return false;
	
	/* PJTWAUS-1 20200310 modify */
	var flagKeyDown = 0;
	var focusElement = 'a[href], area[href], input:not([disabled]), input:not([readonly]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
	var $obj = $('.solutions-contents');
	var $slick = $obj.find('.product-type .items').on('init', function(slick){	  
		var $this = $(this);
		$this.find('.slick-arrow.slick-prev').on('keydown', function(e) {
			if ( $(this).length > 0 ){
				if ((e.which === 9 && !e.shiftKey)) {
					e.preventDefault();
					var $dotEl = $this.find('.slick-dots > li:first-child');
					if ( !$dotEl.is('.slick-active') ) {
						flagKeyDown = 1;
						$dotEl.find('button').trigger('click');
					} else {
						$this.find('.slick-slide.slick-active').find(focusElement).not('[tabindex="-1"]').first().focus();
					}
				}
			}
		});
		$this.find('.slick-arrow.slick-next').on('keydown', function(e) {
			if ( $(this).length > 0 ){				
				if ((e.which === 9 && e.shiftKey)) {
					e.preventDefault();
					var $dotEl = $this.find('.slick-dots > li:last-child');
					if ( !$dotEl.is('.slick-active') ) {
						flagKeyDown = 2;
						$dotEl.find('button').trigger('click');
					} else {
						$this.find('.slick-slide.slick-active').find(focusElement).not('[tabindex="-1"]').last().focus();
					}
				}
			}
		});
	}).slick({
		infinite: false, // PJTWAUS-1
		listStyle: true, /* PJTWAUS-1 : 20200203 add */
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows : true,
		dots: true,
		listStyle: true, // PJTWAUS-1
		responsive: [{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows : true
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				arrows : false
			}
		}],
		prevArrow: carouselOptions.bigAnglePrev, // common.js variable
		nextArrow: carouselOptions.bigAngleNext // common.js variable
	}).on('afterChange', function(event, slick, currentSlide, nextSlide){
		var $this = $(this);
		if ( flagKeyDown === 1 ) {
			$this.find('.slick-slide.slick-active').find(focusElement).not('[tabindex="-1"]').first().focus();
		}
		if ( flagKeyDown === 2 ) {
			$this.find('.slick-slide.slick-active').find(focusElement).not('[tabindex="-1"]').last().focus();
		}
		flagKeyDown = 0;	
	});
	/* //PJTWAUS-1 20200212 modify */

	var length = $slick.find('.item').length;
	if(length <= 4) $slick.addClass('lte-4');
	if(length <= 3) $slick.addClass('lte-3');
	if(length <= 2) $slick.addClass('lte-2');

	// fix height - 'Select a product type first' area
	/*
	var resizeTimer;
	$(window).on('resize', function(){
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(fitHeight, 250);
	}).resize();
	function fitHeight() {
		if($slick.hasClass('slick-initialized') && $slick.width()>0) {
			var len = $slick.find('.item').length,
				max=0;
			$slick.find('.item').css('height', 'auto');
			for(var i=0; i<len; i++) {
				var h = parseInt($slick.find('.item').eq(i).outerHeight());
				if(max<h) max=h;
			}
			$slick.find('.item').css('height', max);
		}
	}
	*/

	// modal : solution category scripts
	var mainselect = document.getElementById('mainCategorySelect');
	var subselect = document.getElementById('subCategorySelect');
	var topicselect = document.getElementById('topicSelect');

	var categoryWrap = document.querySelectorAll('.item:not(.slick-cloned) .category');
	var category = {
		$main: $(categoryWrap).find(".main-category a"),
		$sub: $(categoryWrap).find("ul"),
		initialized: false,
		init: function(){
			if(!category.initialized) {
				category.creatOptions(mainselect, category.$main, true);
				category.addEvent();
				category.initialized = true;
			}
		},
		creatOptions: function(t, e, isMain){
			var l = $(e).length;
			var html = '';
			// selectbox placeholder
			if(t.getAttribute('data-placeholder')) {
				html += '<option value="" disabled selected>'+t.getAttribute('data-placeholder')+'</option>';
			}

			if(e && e.length > 0) {
				for (var idx = 0; idx<l; idx++) {
					var setData = {
						value: null,
						title: null
					};
					var el = e[idx];

					if(e.constructor == Array) {
						el = e[idx];
						// setData.value = el.value;
						setData.value = el.title;
						setData.title = el.title;
					}else {
						el = $(e)[idx];
						setData.value = el.getAttribute('data-val');
						setData.title = el.innerHTML;
					}
					
					html += '<option value="'+setData.value+'">'+setData.title+'</option>';
					if(isMain) category.$sub[idx].setAttribute('data-main-val', setData.value);
				}

				// topicselect.setAttribute('required', 'required');
				t.removeAttribute('disabled');
			}else {
				topicselect.setAttribute('disabled', 'disabled');
				// topicselect.removeAttribute('required');
			}
			
			html = xssfilter(html, true);
			$(t).html(html);
			$(t).trigger('chosen:updated');
		},
		addEvent: function(){
			$(mainselect).on({
				'change.main': function(){
					var val = this.value;
					// var test = category.$main.filter('a[data-val='+val+']');
					var $sub = category.$sub.filter('ul[data-main-val="'+ val +'"]');
					category.creatOptions(subselect, $sub.find('a'));
					topicselect.setAttribute('disabled', 'disabled');
					subselect.value = '';
					topicselect.value = '';
					$(subselect).trigger('chosen:updated');
					$(topicselect).trigger('chosen:updated');
				}
			});
			$(subselect).on({
				'change.sub': function(){
					var param = $(modal.form).serialize();
					// var data = ajax.call(modal.dataUrl, param);
					ajax.call(modal.dataUrl, param, 'json', function(d){
						var data = d instanceof Array ? d : d.data;
						category.creatOptions(topicselect, data);
					});
					topicselect.value = '';
					$(topicselect).trigger('chosen:updated');
				}
			});
		}
	};

	var modal = {
		el: document.getElementById("modal_pickaTopic"),
		form: document.getElementById("topicSelectorForm"),
		submit: document.getElementById("topicSubmit"),
		dataUrl: '',
		init: function(){
			if($(modal.el).length==0) return false;
			modal.dataUrl = modal.form.getAttribute('data-url');
			modal.addEvent();
		},
		validation: function(t){
			var _this = t;
			var select = $(_this).find('select[required]');
			var result = true;

			var l = select.length;
			for (var idx = 0; idx<l; idx++) {
				if(select[idx].value == '') {
					select.eq(idx).parent().addClass('required');
					result = false;
				}else {
					select.eq(idx).parent().removeClass('required');
				}
			}
			return result;
		},
		addEvent: function(){
			$(modal.el).on({
				'show.bs.modal': function(e){
					var anchor = e.relatedTarget; // a triggered anchor
					var sub = $(anchor).parents('.sub-category')[0];
					var mainValue = anchor.getAttribute('data-val'),
						subValue = null;
					
					if(!category.initialized) category.init();

					$(modal.form).find('field-block').removeClass('required');

					if(sub) { // is sub category
						mainValue = sub.getAttribute('data-main-val');
						subValue = anchor.getAttribute('data-val');
					}

					mainselect.value = mainValue;
					$(mainselect).trigger('chosen:updated').trigger('change.main');
					
					if(subValue) {
						subselect.value = subValue;
						$(subselect).trigger('chosen:updated').trigger('change.sub');
					}
				}
			});
			$(modal.submit).on({
				click: function(){
					$(modal.form).submit();
				}
			});
			$(modal.form).on({
				submit: function(e){
					e.preventDefault();
					var validation = modal.validation(this);
					if(validation) {
						// if(topicselect.value && topicselect.value != '') {
						// 	window.location.href = topicselect.value;
						// }else {
							// console.log('empty topic');
							this.submit();
						// }
					}
				}
			});
			$(modal.form).on({
				change: function(e){
					$('.field-block').removeClass('required');
				}
			}, 'select');
		}
	};


	modal.init();


	// scripts for solutions 
	var solutionCategoryArea = $('.category-browse-area');
	if(solutionCategoryArea.length>0) {
		var btnChooseBoxOpen = solutionCategoryArea.find('.btn-open'),
			btnChooseBoxClose = solutionCategoryArea.find('.btn-close'),
			chooseBoxArea = solutionCategoryArea.find('.category-content');

		btnChooseBoxOpen.find('button').on('click', function(event) {
			event.preventDefault();
			chooseBoxArea.addClass('active');
			btnChooseBoxOpen.hide();
			// slick repositioning
			$obj.find('.product-type .items').slick('setPosition');
			chooseBoxArea.attr('tabindex', -1).focus();
			//setTimeout(fitHeight, 350);
		});
		btnChooseBoxClose.find('button').on('click', function(event) {
			event.preventDefault();
			chooseBoxArea.removeClass('active');
			btnChooseBoxOpen.show();
		});
	}


	// Script for other products selected on home page.
	var querySegments, queryString = window.location.href.split("?");
	if (queryString.length > 1) {
		querySegments = queryString[1].split("&");
		for (query in querySegments) {
			var thisQuery = querySegments[query].split("=");
			if (thisQuery.length > 1 && thisQuery[0] == 'otherProducts') {		
				$(".category-name-area .link-text").trigger("click");
				$("html,body").animate({scrollTop: $(".support-size.solutions").offset().top},"slow");
			}
		}
	}
});
